import React from 'react'
import Carousel from "react-multi-carousel";
import { useMediaQuery } from 'react-responsive'
import ContactSection from '../components/contact-section';
import BlogSlider from '../components/blog-slider';
import "react-multi-carousel/lib/styles.css";
import ScrollAnimation from 'react-animate-on-scroll';



// import banner1 from '../assets/banner-1.jpg';




// const Desktop = ({ children }) => {
//     const isDesktop = useMediaQuery({ minWidth: 1180 })
//     return isDesktop ? children : null
// }

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 })
    return isMobile ? children : null
}


export function Index(props) {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };


    return (
        <>

            <section id='home' className='home'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-10 mx-auto'>


                            <div className='welcome-screen'>
                                <em className='d-none'>Fornax Agency</em>
                                <h1 className=' animate__animated animate__fadeIn'><span className='border-text'>Fornax </span>Digital<span>Agency</span></h1>
                                <p className='mb-5  animate__animated animate__fadeIn'>Transforming Crypto Visions into Reality</p>

                                <a className='button-default  animate__animated animate__fadeInUp' target='_blank' href='https://t.me/agency_nova' rel='noreferrer' title='Contact via Telegram'>
                                    <i className="fa-regular fa-paper-plane"></i> Contact via Telegram
                                </a>

                                <Mobile>
                                    {/* <button className='button-default' data-bs-toggle="modal" data-bs-target="#staticBackdrop" rel='noreferrer' title='Watch Promo'>
                                    <i className="fa-regular fa-circle-play"></i>  Watch Promo
                                </button> */}
                                </Mobile>


                                <div className="social-icons d-flex justify-content-center mt-5 pt-4  animate__animated animate__fadeInUp">
                                    <a className="twitter" rel='noreferrer' title='Twitter' href="https://x.com/nova_crypto_x" target="_blank">
                                        <i className="icon-social-twitter"></i>
                                    </a>

                                    <a className="telegram" rel='noreferrer' title='Telegram' href="https://t.me/agency_nova" target="_blank">
                                    <i className="bi bi-send"></i>
                                    </a>
                                    <a className="whatsapp" rel='noreferrer' title='Whatsapp' href="https://whatsapp.com/channel/0029VagPThE2phHDvDrIQn26" target="_blank">
                                    <i className="bi bi-whatsapp"></i>
                                    </a>

                                    <a className="facebook" rel='noreferrer' title='Facebook' href="https://www.facebook.com/NovaCryptoAgency/" target="_blank">
                                        <i className="icon-social-facebook"></i>
                                    </a>
                                    <a className="linkedin" rel='noreferrer' title='Linkedin' href="https://medium.com/@novacryptoagency" target="_blank">
                                    <i className="fa-brands fa-medium"></i>
                                    </a>
                                    <a className="email" rel='noreferrer' title='E-Mail' href="mailto:info@novacryptoagency.com" target="_blank">
                                        <i className="icon-envelope-open"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className='content-style-02 pt-5 mt-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5'>
                        <ScrollAnimation animateIn="fadeInLeft">
                        <h2>Empowering Your Crypto Success</h2>
    </ScrollAnimation>
                   

                        </div>
                        <div className='col-lg-7'>
                        <ScrollAnimation animateIn="fadeInRight">
                            <p>
                                We offer comprehensive services in marketing and software development, tailored to elevate your brand and drive success. Our expert team ensures continuous growth and innovative solutions in the ever-evolving crypto space. From Google Ads to influencer marketing, we cover all aspects to maximize your reach. Partner with us for strategic, impactful, and results-driven campaigns.                            </p>
                                </ScrollAnimation>
                        </div>

                    </div>

                </div>


            </section>

            <section id='features' className='key-features-section py-6'>


                <div className='container'>
                    <div className="row justify-content-center items">
                        <div className='col-lg-12'>
                            <div className='feature-column mb-5'>
                            <ScrollAnimation animateIn="fadeInDown">
                                <p className='sub-title'>NOVA SERVICES</p>
                                <h2>Our Services</h2>
                                </ScrollAnimation>

                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 item'>
                        <ScrollAnimation animateIn="fadeInLeft">

                            <div className="card help-card featured">
                                <i className="fa-regular fa-chess-queen"></i>
                                <a href='/marketing-strategy' title='Marketing Strategy'>
                                    <h4>Marketing Strategy</h4>
                                </a>
                                <p>We analyze your strategy, define your goals, and implement the best marketing tactics</p>
                            </div>
                            </ScrollAnimation>
                        </div>

                        <div className='col-12 col-md-6 col-lg-4 item'>
                        <ScrollAnimation animateIn="fadeIn">
                            <div className="card help-card">
                                <i className="fa-regular fa-lightbulb"></i>
                                <a href='/influencer-marketing' title='Influencer Marketing'>
                                    <h4>Influencer Marketing</h4>
                                </a>
                                <p>Collaborating with top influencers, we connect your brand with the right audience.</p>  
                          </div>
                          </ScrollAnimation>
                        </div>

                        <div className='col-12 col-md-6 col-lg-4 item'>
                        <ScrollAnimation animateIn="fadeInRight">
                            <div className="card help-card">
                                <i className="fa-solid fa-code"></i>
                                <a href='/web-development' title='Web Development'>
                                    <h4>Web Development</h4>
                                </a>
                                <p>We design modern, user-friendly websites, tailored to meet your specific needs.</p>
                            </div>
                            </ScrollAnimation>
                        </div>





                        <div className='col-12 col-md-6 col-lg-4 item'>
                        <ScrollAnimation animateIn="fadeInLeft">
                            <div className="card help-card">
                                <i className="fa-solid fa-film"></i>
                                <a href='/video-promo' title='Video Promo'>
                                    <h4>Video Promo
                                    </h4>
                                </a>
                                <p>We create engaging promotional videos that capture your brand’s essence and vision.</p>
                            </div>
                            </ScrollAnimation>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 item">
                        <ScrollAnimation animateIn="fadeIn">
                            <div className="card help-card">
                                <i className="fa-brands fa-searchengin"></i>
                                <a href='/seo-sem' title='SEO & SEM'>
                                    <h4>SEO & SEM</h4>
                                </a>
                                <p>Optimizing search engine visibility, we drive targeted traffic to your website efficiently.</p>     
                                                       </div>
                                                       </ScrollAnimation>
                        </div>

                        <div className="col-12 col-md-6 col-lg-4 item">
                        <ScrollAnimation animateIn="fadeInRight">
                            <div className="card help-card">
                                <i className="fa-solid fa-compass-drafting"></i>
                                <a href='/seo-sem' title='Graphic Design'>
                                    <h4>Graphic Design</h4>
                                </a>
                                <p>Our team crafts visually stunning designs that effectively communicate your brand’s message.</p>
                            </div>
                            </ScrollAnimation>
                        </div>


                    </div>
                </div>


            </section>

            <section id='ads' className='ads-section gray-bg py-6'>

                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-12'>
                            <div className='feature-column mb-5'>
                            <ScrollAnimation animateIn="fadeInDown">
                                <p className='sub-title'>INNOVATIVE</p>
                                <h2>Marketing</h2>
                                </ScrollAnimation>

                            </div>
                        </div>


                        <div className='col-lg-12'>
                        <ScrollAnimation animateIn="fadeIn">
                            <Carousel responsive={responsive} arrows={false} autoPlay={true}
                                autoPlaySpeed={2000} infinite={false}
                                transitionDuration={500} dotListclassName="custom-dot-list-style"
                                itemclassName="carousel-item-padding-0-px" draggable={true}
                                showDots={true}>


                                <div className='advanced-content-2 mb-4 carousel-item-padding-40-px'>
                                    <div className='icon-box'>

                                        <i className="fa-brands fa-youtube"></i>
                                    </div>
                                    <div className='content-box'>
                                        <h3>Youtube Ads</h3>
                                        <p>
                                            Nunc venenatis in mauris non gravida. Proin efficitur ligula aliquam, ornare lacus vitae, venenatis ligula.



                                        </p>
                                    </div>

                                </div>

                                <div className='advanced-content-2 mb-4'>
                                    <div className='icon-box'>

                                        <i className="fa-brands fa-x-twitter"></i>
                                    </div>
                                    <div className='content-box'>
                                        <h3>X (Twitter) Ads</h3>
                                        <p>
                                            Nunc venenatis in mauris non gravida. Proin efficitur ligula aliquam, ornare lacus vitae, venenatis ligula.




                                        </p>
                                    </div>

                                </div>
                                <div className='advanced-content-2 mb-4'>
                                    <div className='icon-box'>

                                        <i className="fa-regular fa-lightbulb"></i>
                                    </div>
                                    <div className='content-box'>
                                        <h3>Influencer Ads</h3>
                                        <p>
                                            Nunc venenatis in mauris non gravida. Proin efficitur ligula aliquam, ornare lacus vitae, venenatis ligula.




                                        </p>
                                    </div>

                                </div>
                                <div className='advanced-content-2 mb-4'>
                                    <div className='icon-box'>

                                        <i className="fa-solid fa-wand-magic-sparkles"></i>
                                    </div>
                                    <div className='content-box'>
                                        <h3>Banner Ads</h3>
                                        <p>
                                            Nunc venenatis in mauris non gravida. Proin efficitur ligula aliquam, ornare lacus vitae, venenatis ligula.




                                        </p>
                                    </div>

                                </div>

                                <div className='advanced-content-2 mb-4'>
                                    <div className='icon-box'>

                                        <i className="fa-regular fa-window-restore"></i>
                                    </div>
                                    <div className='content-box'>
                                        <h3>Custom Campaigns</h3>
                                        <p>
                                            Nunc venenatis in mauris non gravida. Proin efficitur ligula aliquam, ornare lacus vitae, venenatis ligula.
                                        </p>
                                    </div>

                                </div>
                            </Carousel>
                            </ScrollAnimation>
                        </div>


                    </div>
                </div>
            </section>

            <section id="faqs-block" className="faqs-block py-6">

                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='feature-column mb-5 pb-4'>
                            <ScrollAnimation animateIn="fadeInDown">
                                <p className='sub-title'>FAQS</p>
                                <h2>Frequently Asked Questions
                                </h2>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="fadeInUp">
                                <em> We create marketing strategies tailored to your needs for projects of all scales. We have answered the FAQs for you.


                                </em>
                                </ScrollAnimation>
                            </div>


                        </div>

                        <div className='col-lg-6  pe-lg-5'>
                        <ScrollAnimation animateIn="fadeInLeft">
                            <div className='text-block d-flex justify-content-start mb-5'>
                     
                                <div className='text-block-icon'>
                                    <i className="fa-solid fa-file-invoice-dollar"></i>
                                </div>
                                <div className='text-block-content'>
                                    <h3>What are the limits for the Ad budget?
                                    </h3>
                                    <p>
                                        There are no specific lower or upper limits. We adjust campaigns based on realistic goals proportional to your budget. Regardless of your budget, you can contact us to learn for free what we can do for you.
                                    </p>
                                </div>
                               
                            </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInLeft">
                            <div className='text-block d-flex justify-content-start mb-5'>

                                <div className='text-block-icon'>
                                    <i className="fa-solid fa-chess-knight"></i>
                                </div>
                                <div className='text-block-content'>
                                    <h3>What is the service fee for consultancy?
                                    </h3>
                                    <p>
                                        The initial stage is getting to know you and understanding your project, and this is free of charge. We either include our services in the campaign budget according to their areas or price them separately for web projects.
                                    </p>
                                    
                                </div>
                    
                            </div>
                            </ScrollAnimation>
                      
                        </div>

                        <div className='col-lg-6  ps-lg-5'>
                        <ScrollAnimation animateIn="fadeInRight">
                            <div className='text-block d-flex justify-content-start mb-5'>
        
                                <div className='text-block-icon'>
                                    <i className="fa-solid fa-folder-tree"></i>
                                </div>
                                <div className='text-block-content'>
                                    <h3>Do you create digital materials?

                                    </h3>
                                    <p>
                                        Yes. The foundation of marketing consists of digital materials that every investor needs to understand your project. In this scope, we create and update materials such as websites, pitch decks, banners, and videos.
                                    </p>
                                </div>
                            
                            </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInRight">
                            <div className='text-block d-flex justify-content-start mb-5'>
                      
                                <div className='text-block-icon'>
                                    <i className="fa-solid fa-credit-card"></i>
                                </div>
                                <div className='text-block-content'>
                                    <h3>Do you accept payment with tokens?
                                    </h3>
                                    <p>
                                        Yes, we can accept token payments for some of our services. The option to pay with tokens depends on the liquidity and volatility of the project's token. However, for web services and CPC Ads, we accept stablecoins.
                                    </p>
                                </div>
                             
                            </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </section>

            <ScrollAnimation animateIn="fadeIn">
            <BlogSlider></BlogSlider>
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeIn">
            <ContactSection></ContactSection>

            </ScrollAnimation>



            <div className="modal fade" id="staticBackdrop" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content-lightbox">
                        <button type="button" className="lightbox-close" data-bs-dismiss="modal" aria-label="Close"></button>

                        <div className="modal-body">
                        </div>

                    </div>
                </div>
            </div>

            <div className="modal fade terms-modal" id="termsModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="termsModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="termsModalLabel">Terms of Use</h1>
                            <button type="button" className="button-close" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg"></i></button>
                        </div>
                        <div className="modal-body">
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
