import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import logo from '../assets/logo.svg';
import ScrollToTopButton from './scroll';
import { useLocation } from 'react-router-dom';

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1180 });
    return isDesktop ? children : null;
};

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1179 });
    return isMobile ? children : null;
};

export function Header(props) {
    const [scroll, setScroll] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            setScroll(window.scrollY > 50);
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const getClassName = (path, hasDropdown) => {
        return `nav-link ${hasDropdown ? 'dropdown-toggle' : ''} ${location.pathname === path ? 'active' : ''}`.trim();
    };

    const handleDropdownClick = (event) => {
        const target = event.currentTarget;
        if (target.getAttribute('href') !== '#') {
            // Navigate to the link
            window.location.href = target.getAttribute('href');
        }
    };

    return (
        <>
            <header>
                <div className={scroll ? "sticky sticky-fixed" : "sticky"}>
                    <div className='container'>
                        <div className='row g-0 align-items-center justify-content-between'>
                            <div className='col-lg-2 col-6'>
                                <a href='/' className='logo' title='Nova Crypto Marketing Agency'>
                                    <img src={logo} title='Nova Crypto Marketing Agency' alt='Nova Crypto Marketing Agency' />
                                </a>
                            </div>

                            <Desktop>
                                <div className='col-lg-7'>
                                    <nav id="main-nav" className="main-menu navbar navbar-expand-lg">
                                        <div className="collapse navbar-collapse" id="navbarMain">
                                            <ul className="navbar-nav">
                                                <li className="nav-item">
                                                    <a href="/" className={getClassName('/', false)}>Home</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/about-us" className={getClassName('/about-us', false)}>About Us</a>
                                                </li>
                                                <li className="nav-item dropdown">
                                                    <a href="/services" className={getClassName('/services', true)} role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleDropdownClick}>Services</a>
                                                    <ul className="dropdown-menu">
                                                        <li><a className="dropdown-item" href="/web-development">Web Development</a></li>
                                                        <li><a className="dropdown-item" href="/web-development">Mobile App Development</a></li>
                                                        <li><a className="dropdown-item" href="/graphic-design">Web Design</a></li>
                                                        <li><a className="dropdown-item" href="/web-development">Cyber ​​Security</a></li>
                                                    </ul>
                                                </li>
                                                <li className="nav-item dropdown">
                                                    <a href="/marketing" className={getClassName('/marketing', true)} role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleDropdownClick}>Marketing</a>
                                                    <ul className="dropdown-menu">
                                                        <li><a className="dropdown-item" href="/marketing-strategy">Marketing Strategy</a></li>
                                                        <li><a className="dropdown-item" href="/google-ads">Google Ads</a></li>
                                                        <li><a className="dropdown-item" href="/x-ads">X (Twitter) Ads</a></li>
                                                        <li><a className="dropdown-item" href="/custom-campaigns">Custom Campaigns</a></li>
                                                        <li><a className="dropdown-item" href="/seo-sem">SEO & SEM</a></li>
                                                        <li><a className="dropdown-item" href="/video-promo">Video Promo</a></li>
                                                        
                                                    </ul>
                                                </li>

                                                <li className="nav-item">
                                                    <a href="/faqs" className={getClassName('/faqs', false)}>FAQs</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/contact" className={getClassName('/contact', false)}>Contact</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </Desktop>

                            <Mobile>
                                <div className="offcanvas offcanvas-start sidebar" tabIndex="-1" id="sideBar" aria-labelledby="sidebarLabel">
                                    <div className="offcanvas-header">
                                        <h5 className="offcanvas-title" id="sidebarLabel">Menu</h5>
                                        <button type="button" className="button-close" data-bs-dismiss="offcanvas" aria-label="Close"><i className="bi bi-x-lg"></i></button>
                                    </div>
                                    <div className="offcanvas-body">
                                        <nav id="main-nav" className="sidebar-menu navbar navbar-expand">
                                            <div className="collapse navbar-collapse" id="navbarMain">
                                                <ul className="navbar-nav">
                                                    <li className="nav-item">
                                                        <a href="/" className={getClassName('/', false)}>Home</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="/about-us" className={getClassName('/about-us', false)}>About Us</a>
                                                    </li>
                                                    <li className="nav-item dropdown">
                                                        <a href="/marketing" className={getClassName('/marketing', true)} role="button" data-bs-toggle="dropdown" aria-expanded="false">Marketing</a>
                                                        <ul className="dropdown-menu">
                                                            <li><a className="dropdown-item" href="/marketing-strategy">Marketing Strategy</a></li>
                                                            <li><a className="dropdown-item" href="/influencer-marketing">Influencer Ads</a></li>
                                                            <li><a className="dropdown-item" href="/platform-ads">Platform Ads</a></li>
                                                            <li><a className="dropdown-item" href="/google-ads">Google Ads</a></li>
                                                            <li><a className="dropdown-item" href="/x-ads">X (Twitter) Ads</a></li>
                                                            <li><a className="dropdown-item" href="/custom-campaigns">Custom Campaigns</a></li>
                                                        </ul>
                                                    </li>
                                                    <li className="nav-item dropdown">
                                                    <a href="/services" className={getClassName('/services', true)} role="button" data-bs-toggle="dropdown" aria-expanded="false">Services</a>
                                                    <ul className="dropdown-menu">
                                                        <li><a className="dropdown-item" href="/web-development">Web Development</a></li>
                                                        <li><a className="dropdown-item" href="/graphic-design">Graphic Design</a></li>
                                                        <li><a className="dropdown-item" href="/seo-sem">SEO & SEM</a></li>
                                                        <li><a className="dropdown-item" href="/video-promo">Video Promo</a></li>
                                                    </ul>
                                                </li>
                                                    <li className="nav-item">
                                                        <a href="/faqs" className={getClassName('/faqs', false)}>FAQs</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="/contact" className={getClassName('/contact', false)}>Contact</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </Mobile>

                            <div className='col-lg-2 col-6 d-flex justify-content-end'>
                                <Mobile>
                                    <button className="navbar-toggler" name="Sidebar" type="button" data-bs-toggle="offcanvas" data-bs-target="#sideBar" aria-controls="sideBar">
                                        <i className="bi bi-text-left"></i>
                                    </button>
                                </Mobile>
                                <Desktop>
                                    <a href="/get-in-touch" title='Get in Touch' className="button-default">Get in Touch</a>
                                </Desktop>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </header>
        </>
    );
}
